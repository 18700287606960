import React, { useEffect } from "react";

const TermlyPolicy: React.FC<{ policyId: string }> = ({ policyId }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.id = "termly-jssdk";
    script.src = "https://app.termly.io/embed-policy.min.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [policyId]);

  return <div name="termly-embed" data-id={policyId} data-type="iframe"></div>;
};

export default TermlyPolicy;
